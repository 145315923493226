// import Datepicker from "./Datapicker";
// import AddTable from "./AddTable";
// import Header from "./Header"
// import React, { useState } from 'react';
// import { useLocation } from "react-router-dom";


// function Home() {
//   const [searchQuery, setSearchQuery] = useState('');
//   const location = useLocation();

//   // Retrieve the username from the location state
//   const username = location.state?.username || '';
 
//   return (


//    <div>
//      <Header></Header>
//      <Datepicker onSearch={setSearchQuery} />
//       <AddTable searchQuery={searchQuery} />
//      </div>

//  );
// }

// export default Home;

import Datepicker from "./Datapicker"; // Assuming the correct file name is "Datepicker"
import AddTable from "./AddTable";
import Header from "./Header"
import SideSlidePanel from './SideSlidePanel'; 
import React, { useState , useEffect} from 'react';
import { useLocation } from "react-router-dom";


function Home() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [changedData, setChangedData] = useState([]);

 
  const location = useLocation();


  // Retrieve the username from the location state
  const username = location.state?.username || '';

  // Callback to handle date selection in Datepicker
  const handleDateSelect = (fromDate, toDate,filters) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
    setSelectedFilters(filters);
  };

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
    console.log(filters,"home")
  };

  const handleChangeData = (data) => {
    setChangedData(data);
  };



  return (
    <div style={{ position: "relative" }}>
      <Header />
      <Datepicker onSearch={setSearchQuery} onDateSelect={handleDateSelect} handleFilterChange={handleFilterChange} changedData={changedData}/>
      <AddTable searchQuery={searchQuery} selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} selectedFilters={selectedFilters} onChangeData={handleChangeData}/>
    </div>
  );
}

export default Home;

