// SettingsPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import FileUpload from './FileUpload';
import './mini.css'
import History from './History';
import Profile from './icons/Profile.png'
import Header from './Header';

function SettingsPage() {

  const location = useLocation();

  // Retrieve the username from the location state
  const username = location.state?.username || '';


  return (
    <div className='FileUpload'>
     <div style={{display:"flex",alignItems:"center", justifyContent:"space-between"}}
     > 
     <p style={{color:"#1DA1F2",display:"flex",alignItems:"centre",justifyContent:"flex-start", fontSize:'24px',
     fontWeight:'600'}}
     >
      Files
      </p> <div style={{ display: 'flex', alignItems: 'center' , paddingRight:'20px'}}>
        <img src={Profile} style={{display:"flex",justifyContent:"flex-end"
        ,alignItems:"center",paddingRight:"10px"}}/>
        <span>{username.toUpperCase()}</span>
      </div>
      </div>
      <hr/>
      
      <FileUpload />
      {/* Additional settings content can be added here */}
      <History />
      <Header/>
    </div>

  );
}

export default SettingsPage;