import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {setFiles,setFileStatus,enableSyncButton,disableSyncButton,setRemainingProgress,} from './actions';
import data from './Data/description.json';
import feather_upload from './icons/feather_upload-cloud.png';
import upload from './icons/upload.png';
import cancel from './icons/cancel.png';
import { useStore } from 'react-redux';
import Popup from './Popup';
import success from './icons/success.png';
import SuccessPopup from './successPopup';

const FileUpload = () => {
  const initialData = data;
  const dispatch = useDispatch();
  const files = useSelector((state) => state.file.files);
  const fileStatus = useSelector((state) => state.file.fileStatus);
  const syncButtonEnabled = useSelector((state) => state.file.syncButtonEnabled);
  const store = useStore();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const remainingProgress = useSelector((state) => state.file.remainingProgress);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [invalidFileNames, setInvalidFileNames] = useState([]);


  const fileInputRef = useRef(null);

  // const isCorrectFormat = (files) => {
  //   const allowedPrefixes = ['_str', '_ghr', '_tcr', '_frr'];

  //   for (const file of files) {
  //     const isMatchingFormat = allowedPrefixes.some((prefix) => file.name.includes(prefix));
  //     if (!isMatchingFormat) {
  //       return false;
  //     }
  //   }

  //   return true;
  // };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length === 0 ) {
      setPopupMessage('Please upload files with the correct format (filename_*.csv).');
      setPopupOpen(true);
      return;
    }

    dispatch(setFiles(selectedFiles));
    dispatch(setFileStatus(selectedFiles.map(() => ({ progress: 0, color: 'red' }))));
    dispatch(enableSyncButton());

    const uploadPromises = selectedFiles.map((file, index) => handleFileUpload(file, index));

    Promise.all(uploadPromises).then(() => {
      // All files are uploaded, you can perform any necessary actions here
    });
  };

  const closePopup = () => {
    setPopupOpen(false);
    setPopupMessage('');
  };

  const handleFileUpload = async (file, index) => {
    const totalProgress = 100;
    const updateInterval = 500;
    const numSteps = totalProgress / 10;

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    for (let step = 0; step <= numSteps; step++) {
      const progress = (step / numSteps) * totalProgress;
      const remainingProgress = totalProgress - progress;

      const currentFiles = store.getState().file.files;
      const currentFileStatus = store.getState().file.fileStatus;

      const updatedFileStatus = Array.from({ length: currentFiles.length }, (_, i) => {
        const fileSize = currentFiles[i]?.size || 0;
        const fileProgress = i === index ? progress : currentFileStatus[i]?.progress || 0;

        return {
          progress: fileProgress,
          size: fileSize,
          color: progress >= 0 ? 'green' : 'red',
        };
      });

      // if (progress === totalProgress && !showSuccessPopup) {
      //   setShowSuccessPopup(true);
      // }

      dispatch(setFileStatus(updatedFileStatus));
      dispatch(setRemainingProgress(remainingProgress));

      await delay(updateInterval);
    }

    // After the file upload is complete, send the file to the API
    await handleFileUploadToAPI(file, index);
  };

  const handleFileUploadToAPI = async (file, index) => {
    const formData = new FormData();
    formData.append('csvFiles', file);

    try {
      const response = await axios.post('https://varapi.vthinkglobal.com/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('File uploaded to API successfully:', response.data);

      if (response.data.message) {
        // File upload successful
        if (!showSuccessPopup) {
          setShowSuccessPopup(true);
        }
      } else if (response.data.invalidFileNames) {
        // File upload failed
        // Display error message in a popup for each invalid file
        setInvalidFileNames((prevInvalidFileNames) => [
          ...prevInvalidFileNames,
          ...response.data.invalidFileNames,
        ]);
        setPopupMessage(`Invalid files: ${invalidFileNames.join(', ')}`);
        setPopupOpen(true);
      }
    } catch (error) {
      throw error;
    }
  };

    const formatFileSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  
    if (bytes === 0) return '0 Byte';
  
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const handleCancelClick = (index) => {
    // Dispatch an action to update the file status for the specified index
    dispatch(setFileStatus([
      ...fileStatus.slice(0, index),
      null,
      ...fileStatus.slice(index + 1)
    ]));
  };

  return (
    <div style={{ display: 'flex', paddingTop: '50px' }}>
        <div>
        <div
          // onDrop={handleDrop}
          // onDragOver={(e) => e.preventDefault()}
          style={{
            border: '1px solid #ccc',
            padding: '20px',
            width: '250px',
            height: '260px',
            marginRight: '20px',
            backgroundColor:'#FFFFFF',
            borderRadius: '9px',
            boxShadow:'0px 0px 5px 2px rgba(156, 156, 156, 0.15)'
          }}
        >
          <label htmlFor="fileInput" className="upload-label">
            <img
              src={feather_upload}
              alt="Upload Icon"
              className="upload-icon"
              style={{ paddingLeft: '90px', paddingTop: '60px' }}
            />
          </label>
          <Popup
            isOpen={isPopupOpen}
            onRequestClose={() => setPopupOpen(false)}
            message={`Error uploading file: ${popupMessage}. Invalid files: ${invalidFileNames.join(', ')}`}
          />
          <input
            type="file"
            id="fileInput"
            accept=".csv"
            style={{ display: 'none' }}
            multiple
            onChange={handleFileChange}
          />
          <br />
          <p style={{ display: 'flex', justifyContent: 'center', color: '#B6B6B6' }}>
            Drag & Drop Files <br />
            Support type .csv
          </p>
          <div style={{ position: 'relative', paddingTop: '100px' }}>
            <p style={{ paddingLeft: '100px' }}>or</p>
            <button
              type="button"
              className="upload"
              style={{ border: 'none', background: 'none' }}
              onClick={handleUploadButtonClick}
            >
              <img src={upload} alt="upload" />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".csv"
              onChange={handleFileChange}
              multiple
            />
          </div>
        </div>
      </div>

      {files.length === 0 ? (
        <div style={{ flex: 1, paddingLeft: '45px' }}>
          {initialData.map((file, index) => (
            <div key={index}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='title '>
                  <img src={file.img} class="icon" />
                  <p className='text'> {file.name}</p>
                </div>
                <div className='title'>
                  <p className='paratext'>{file.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          {files.map((file, index) => (
            <div key={index}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='progressDiv'>
                  <strong className='fileUploadName'> {file.name}</strong>
                  <strong>{formatFileSize(file.size)}</strong>
                  
                    
                    {fileStatus[index]?.progress === 100 ? (
                      <button className='cancelProgress'>
                    <img src={success} alt="completed cancel Icon" />
                    </button>
                      ) : (
                        <button onClick={() => handleCancelClick(index)} className='cancelProgress'>
                    <img src={cancel} alt="cancel Icon" />
                    </button>
                      )}
                  
                </div>
                <div>
                  <strong>{file.description}</strong>
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    width: "98%",
                    height: '13px',
                    marginBottom: '10px',
                    borderRadius: '10px',
                    overflow: 'hidden', // Ensures the progress bar stays within the border
                  }}
                  className='borderForProgress'
                >
                  <div
                    style={{
                      width: `${fileStatus[index]?.progress}%`,
                      backgroundColor: fileStatus[index] ? fileStatus[index].color : 'red',
                      height: '100%', // Set the height to 100% to fill the container vertically
                      paddingLeft: '14px',
                      borderRadius: '10px',
                      transition: 'width 0.3s ease', // Add a transition for a smoother effect
                    }}
                  ></div>
                </div>


                <div style={{justifyContent:"space-between", display:"flex"}}>
                {fileStatus[index]?.progress !== 100 ? (  
                  <><div>
                      {fileStatus[index] ? `${fileStatus[index].progress}%` : '0%'} -Done
                    </div><div>
                        Remaining Progress: {remainingProgress}%
                      </div></>
                ) : (
                  <div>
                    <strong>Completed</strong>
                    </div>
                )}
              
                </div>
                
                {showSuccessPopup && (
                <SuccessPopup
                  isOpen={showSuccessPopup}
                  onRequestClose={() => setShowSuccessPopup(false)}
                  message="Your files have been successfully uploaded."
                />
              )}
              </div>
            </div>
          ))}
          {/* <button
            onClick={handleSyncButtonClick}
            disabled={!syncButtonEnabled}
            style={{
              height: '40px',
              backgroundColor: '#1DA1F2',
              color: 'white',
              borderRadius: '6px',
              width: '100px',
            }}
          >
            Sync
          </button> */}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
