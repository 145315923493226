import React from 'react';
import { Route, Routes } from 'react-router-dom';
import App from './App';
import SettingsPage from './SettingsPage';
import Login from './Login';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { InteractionType } from "@azure/msal-browser";


const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <MsalAuthenticationTemplate interactionType={InteractionType.Popup}
             >
              <Login />
            </MsalAuthenticationTemplate>
          }
        />
        <Route path="/home" element={<App />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
    </>
  );
};

export default AppRouter;



